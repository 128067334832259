import { XySourceCgiEditMeta, XySourceMethodEditMeta } from '../paramsFormComponents';
import { CATEGORY } from './const';
import { deepInterop } from '@tencent/ui-core/lib/utils/stringInterop';
import { splitPath } from '@tencent/data-schema-core/lib/utils/path';
import { get, set } from 'lodash';
import { getDataSourceMethodReturnSchema } from '@components/uicorePlugin/utils/getDataSourceReturnSchema';
// 调用数据源接口
export const cgiAtom = {
    id: 'xy:source:requestCgi',
    name: '调用数据源接口',
    desc: '调用数据源接口并返回接口调用结果，支持批量调用 (注意该步骤不会更新页面数据)',
    category: CATEGORY.DATASOURCE,
    visible: false,
    paramsSchema: {
        type: 'object',
        fields: [
            {
                id: 'cgiInfo',
                type: 'any',
                title: '',
                default: { sourceId: '', cgi: '', code: '', paramType: 'js' },
                ui: { type: 'XySourceCgiEdit' },
            },
        ],
    },
    paramsFormComponents: [XySourceCgiEditMeta],
    hideReturnValueSchema: true,
    async execute(ctx, params) {
        const { sourceId, cgi, code, paramType, paramObject } = params.cgiInfo || {};
        const state = ctx.renderer.sources[sourceId]?.data;
        const request = state?.$requests[cgi];
        if (typeof request !== 'function') {
            throw new Error(`[调用数据源接口]${cgi}接口找不到`);
        }
        const fetchFn = async (req) => {
            try {
                const res = await request(req);
                return res;
            }
            catch (error) {
                return {
                    error,
                };
            }
        };
        if (!paramType || paramType === 'js') {
            const httpParams = ctx.tryEval(code); // 空也能调用
            if (Array.isArray(httpParams)) {
                // 批量 请求
                const promises = httpParams.map(p => fetchFn(p));
                return await Promise.all(promises);
            }
            return await fetchFn(httpParams);
        }
        if (paramType === 'form') {
            const { body, query, bodyExprStr } = paramObject;
            if (state.promiseForCollection) {
                // 同步加载的数据源，内部collection对象是异步加载的，需要等待
                await state.promiseForCollection;
            }
            const isDB = state.collection.collectionData.type === 'db';
            const queryValue = deepInterop(query, ctx.interopContext, ctx.extraInteropContext);
            let bodyValue = deepInterop(isDB ? bodyExprStr : body, ctx.interopContext, ctx.extraInteropContext);
            if (isDB && typeof bodyValue === 'string') {
                try {
                    bodyValue = JSON.parse(bodyValue);
                }
                catch (error) {
                    console.error('[数据源请求] parse db body fail', error);
                }
            }
            const params = {
                query: queryValue,
                body: bodyValue,
            };
            return await fetchFn(params);
        }
    },
    returnValue: {
        type: 'object',
        fields: [
            {
                id: 'body',
                type: 'object',
                description: '返回包体',
            },
            {
                id: 'error',
                type: 'object',
                description: '错误对象(如果有)',
            },
        ],
    },
};
// 批量调用数据源接口
export const batchCgiAtom = {
    id: 'xy:source:batchRequestCgi',
    name: '批量调用数据源接口',
    desc: '批量调用数据源接口并返回接口调用结果，区别于\'调用数据源接口\'中的前端批量，这里的批量是单接口后端批量。目前只有DB数据源支持此操作',
    category: CATEGORY.DATASOURCE,
    paramsSchema: {
        type: 'object',
        fields: [
            {
                id: 'cgiInfo',
                type: 'any',
                title: '',
                default: { sourceId: '', cgi: '', code: '', paramType: 'js' },
                ui: { type: 'XySourceCgiEdit' },
                'uc:allowReset': false,
                'uc:allowExpr': false,
            },
        ],
    },
    paramsFormComponents: [XySourceCgiEditMeta],
    hideReturnValueSchema: true,
    async execute(ctx, params) {
        const { sourceId, cgi, code, paramType } = params.cgiInfo || {};
        const state = ctx.renderer.sources[sourceId]?.data;
        const request = state?.$requests[cgi];
        if (typeof request !== 'function') {
            throw new Error(`[调用数据源接口]${cgi}接口找不到`);
        }
        const fetchFn = async (req) => {
            try {
                const res = await request(req, { raw: true, batchEnabled: true });
                return res;
            }
            catch (error) {
                return {
                    error,
                };
            }
        };
        // 批量模式目前只能js传参
        if (!paramType || paramType === 'js') {
            const httpParams = ctx.tryEval(code); // 空也能调用
            return await fetchFn(httpParams);
        }
    },
    returnValue: {
        type: 'array',
        items: {
            type: 'object',
            fields: [
                {
                    id: 'body',
                    type: 'object',
                    description: '返回包体',
                },
                {
                    id: 'error',
                    type: 'object',
                    description: '错误对象(如果有)',
                },
            ],
        },
    },
};
// 数据源操作
export const dsMethodAtom = {
    id: 'xy:invokeDataSourceMethod',
    name: '数据源操作',
    category: '数据源',
    returnValue: (params, { designer, renderer }) => {
        const { dataPath, methodName } = params;
        const defaultSchema = {
            type: 'object',
        };
        if (!dataPath || !methodName)
            return defaultSchema;
        const dataSourceId = /data.([^.]+)/.exec(dataPath)?.[1];
        if (!dataSourceId)
            return defaultSchema;
        const dataSchema = getDataSourceMethodReturnSchema(designer, renderer, dataSourceId, methodName) || defaultSchema;
        return dataSchema;
    },
    hideReturnValueSchema: true,
    paramsSchema: {
        type: 'object',
        ui: { type: 'XySourceMethodEdit' },
        fields: [
            {
                id: 'dataPath',
                title: '哪一个数据源,值格式：data.{sourceId}.$methods,其中 sourceId 要通过 Information 指令获取',
                type: 'string',
                default: '',
            },
            {
                id: 'methodName',
                type: 'string',
                title: '数据源的方法ID，通过 Information 指令获取。',
                default: '',
            },
            {
                id: 'arguments',
                type: 'array',
                items: { type: 'string' },
                title: '参数',
                default: [],
            },
            {
                id: 'argumentsType',
                type: 'string',
                default: 'none',
            },
            {
                id: 'formArguments',
                items: { type: 'any' },
                title: '参数',
                default: {},
                // JOSN.stringify会把 [undefined,{}]转成[null,{}] 使用对象存arguments
                // [boolean ,{body,query,bodyExprStr}] => {'0': true ,'1':{body,query,bodyExprStr} } 数据源的参数一般是这样 http请求参数需要特殊处理
            },
            {
                id: 'extraInfo',
                type: 'object',
                required: false,
            },
        ],
    },
    paramsFormComponents: [XySourceMethodEditMeta],
    execute(ctx, params) {
        const { dataPath, methodName, argumentsType, arguments: args = [], extraInfo, formArguments } = params;
        const data = get(ctx.renderer, splitPath(dataPath));
        if (!data)
            throw new Error(`数据源 ${dataPath} 不存在`);
        const fn = data[methodName];
        if (typeof fn !== 'function')
            throw new Error(`数据源 ${dataPath} 没有名为 ${methodName} 的方法`);
        const isJSModel = getIsJSModel(ctx, dataPath);
        // 新数据源只支持js模式
        if (argumentsType === 'js' || isJSModel) {
            const finalArgs = args.map(expr => (expr ? ctx.tryEval(expr) : undefined));
            return fn.apply(data, finalArgs);
        }
        if (argumentsType === 'none' || !argumentsType) {
            return fn.apply(data);
        }
        if (argumentsType === 'form') {
            const argsArray = Array.isArray(formArguments)
                ? formArguments
                : Object.keys(formArguments).reduce((prev, index) => {
                    set(prev, index, formArguments[index]);
                    return prev;
                }, []);
            const finalArgs = argsArray.map((arg, index) => {
                if (extraInfo?.requestParamsIndex === index) {
                    const { body, query, bodyExprStr } = arg;
                    const isDB = extraInfo?.requestParamsType === 'db';
                    const queryValue = deepInterop(query, ctx.interopContext, ctx.extraInteropContext);
                    let bodyValue = deepInterop(isDB ? bodyExprStr : body, ctx.interopContext, ctx.extraInteropContext);
                    if (isDB && typeof bodyValue === 'string') {
                        try {
                            bodyValue = JSON.parse(bodyValue);
                        }
                        catch (error) {
                            console.error('[数据源请求] parse db body fail', error);
                        }
                    }
                    return {
                        query: queryValue,
                        body: bodyValue,
                    };
                }
                return deepInterop(arg, ctx.interopContext, ctx.extraInteropContext);
            });
            return fn.apply(data, finalArgs);
        }
    },
    getDisplayName(step) {
        const { dataPath, methodName } = step.params;
        if (!dataPath || !methodName)
            return undefined;
        return `${dataPath}.${methodName}`;
    },
};
const getIsJSModel = (ctx, dataPath) => {
    try {
        const is$methods = dataPath.endsWith('.$methods');
        if (!is$methods)
            return false;
        const statePath = dataPath.replace(/\.\$methods$/, '');
        const state = get(ctx.renderer, splitPath(statePath));
        return state?.isJSStateModel;
    }
    catch (error) {
        console.error(error);
        return false;
    }
};
// 数据源重新初始化
export const batchInitAtom = {
    id: 'xy:source:batchInitCgi',
    name: '数据源重新初始化',
    desc: '该行为会批量并发调用所有数据源的初始化(init)方法',
    category: CATEGORY.DATASOURCE,
    paramsSchema: {
        type: 'object',
        fields: [],
    },
    async execute(ctx) {
        const { data } = ctx.renderer ?? {};
        if (data) {
            const ans = await Promise.all(Object.values(data).map((instance) => {
                if (instance.isJSStateModel) {
                    // 新数据源实例
                    return instance?.$methods?.init?.() ?? null;
                }
                // 旧数据源实例
                if (instance?.options?.sourceConfig?.initWhenPageLoad) {
                    return instance?.init?.() ?? null;
                    ;
                }
                return null;
            })
                .filter(Boolean));
            return ans;
        }
    },
};
export default [
    dsMethodAtom,
    cgiAtom,
    batchCgiAtom,
    batchInitAtom,
];
