import { getMethodReturnSchema } from '@components/uicorePlugin/paramsFormComponents/XySourceMethodReturnSchema/utils';
export function getDataSourceMethodReturnSchema(designer, renderer, dataSourceId, methodName) {
    const source = designer.rootPagelet.xySources.find((i) => i.id === dataSourceId);
    const methodConfig = source.methods.find((item) => item.id === methodName);
    if (methodConfig?.type !== 'form') {
        return undefined;
    }
    const dataSourceData = renderer.data[dataSourceId].collection.collectionData;
    const dataSchema = getMethodReturnSchema(dataSourceData, methodName);
    return dataSchema;
}
