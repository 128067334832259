<template>
  <a-menu
    v-if="navShowSidebarGroups.length > 0"
    id="xy-nav-menu"
    :selected-keys="selectedKeys"
    mode="horizontal"
    :theme="navTheme"
    :style="menuHeight"
  >
    <template v-for="group of navShowSidebarGroups">
      <!-- 显示菜单项 -->
      <a-sub-menu
        v-if="isShowMenus(group.groupId)"
        :key="getGroupMenuKey(group.groupId)"
        :popup-class-name="`xy-nav-menu-popup ${navTheme}`"
      >
        <template slot="title">
          <div @click="handleGroupClick(group)">
            {{ group.groupName }}
          </div>
        </template>
        <template v-for="first of group.menus">
          <!-- 有二级菜单 -->
          <a-sub-menu
            v-if="haveSubMenu(first)"
            :key="getGroupMenuKey(group.groupId, [first])"
            :inline-indent="0"
            class="first-menu-item"
          >
            <template slot="title">
              <span class="title-container">
                <public-w-icon-show-new
                  v-if="isShowIcons(group)[0] && isMenuWithIcon(first)"
                  :value="getMenuIcon(first, `${first.name}`)"
                />
                <span class="ellipsis">{{ first.name }}</span>
              </span>
            </template>
            <!-- 二级菜单 -->
            <template v-for="second of getSubMenu(first)">
              <!-- 有三级菜单 -->
              <a-sub-menu
                v-if="haveSubMenu(second)"
                :key="getGroupMenuKey(group.groupId, [first, second])"
                :inline-indent="0"
              >
                <template slot="title">
                  <span class="title-container">
                    <div v-if="!isMenuWithIcon(first)" />
                    <public-w-icon-show-new
                      v-else-if="isShowIcons(group)[1] && isMenuWithIcon(second)"
                      :value="getMenuIcon(second, `${first.name}.${second.name}`)"
                    />
                    <span class="ellipsis">{{ second.name }}</span>
                  </span>
                </template>
                <!-- 三级菜单 -->
                <template v-for="third of getSubMenu(second)">
                  <!-- 有四级菜单 -->
                  <a-sub-menu
                    v-if="haveSubMenu(third)"
                    :key="getGroupMenuKey(group.groupId, [first, second, third])"
                    :inline-indent="0"
                  >
                    <template slot="title">
                      <span class="title-container">
                        <div v-if="!isMenuWithIcon(first)" />
                        <public-w-icon-show-new
                          v-else-if="isShowIcons(group)[1] && isMenuWithIcon(third)"
                          :value="getMenuIcon(third, `${first.name}.${second.name}.${third.name}`)"
                        />
                        <span class="ellipsis">{{ third.name }}</span>
                      </span>
                    </template>
                    <!-- 四级菜单 -->
                    <a-menu-item
                      v-for="fourth of getSubMenu(third)"
                      :key="getGroupMenuKey(group.groupId, [first, second, third, fourth])"
                      :disabled="isMenuDisabled(fourth)"
                      :title="fourth.name"
                      @click="handleMenuClick(fourth)"
                    >
                      <div v-if="!isMenuWithIcon(third)" />
                      <span class="ellipsis">{{ fourth.name }}</span>
                    </a-menu-item>
                  </a-sub-menu>
                  <!-- 无四级菜单 -->
                  <a-menu-item
                    v-else
                    :key="`${getGroupMenuKey(group.groupId, [first, second, third])}`"
                    :disabled="isMenuDisabled(third)"
                    :title="third.name"
                    class="title-container"
                    @click="handleMenuClick(third)"
                  >
                    <public-w-icon-show-new
                      v-if="isShowIcons(group)[1] && isMenuWithIcon(third)"
                      :value="getMenuIcon(third, `${first.name}.${second.name}.${third.name}`)"
                    />
                    <div v-else-if="!isMenuWithIcon(second)" />
                    <span class="ellipsis">{{ third.name }}</span>
                  </a-menu-item>
                </template>
              </a-sub-menu>
              <!-- 无三级菜单 -->
              <a-menu-item
                v-else
                :key="`${getGroupMenuKey(group.groupId, [first, second])}`"
                :disabled="isMenuDisabled(second)"
                :title="second.name"
                class="title-container"
                @click="handleMenuClick(second)"
              >
                <public-w-icon-show-new
                  v-if="isShowIcons(group)[1] && isMenuWithIcon(second)"
                  :value="getMenuIcon(second, `${first.name}.${second.name}`)"
                />
                <div v-else-if="!isMenuWithIcon(first)" />
                <span class="ellipsis">{{ second.name }}</span>
              </a-menu-item>
            </template>
          </a-sub-menu>
          <!-- 无二级菜单 -->
          <a-menu-item
            v-else
            :key="`${getGroupMenuKey(group.groupId, [first])}`"
            class="first-menu-item title-container"
            :disabled="isMenuDisabled(first)"
            :title="first.name"
            @click="handleMenuClick(first)"
          >
            <public-w-icon-show-new
              v-if="isShowIcons(group)[0] && isMenuWithIcon(first)"
              :value="getMenuIcon(first)"
            />
            <span class="ellipsis">{{ first.name }}</span>
          </a-menu-item>
        </template>
      </a-sub-menu>
      <!-- 不显示菜单项 -->
      <a-menu-item
        v-else
        :key="`${getGroupMenuKey(group.groupId)}`"
        @click="handleGroupClick(group)"
      >
        {{ group.groupName }}
      </a-menu-item>
    </template>
  </a-menu>
</template>

<script>
import { inject, toRefs, computed } from '@vue/composition-api';
import { toPageByMenu, normalizeRouteConfig, isRegexPath } from '@utils/path';
import {
  getSubMenu,
  haveSubMenu,
  getMenuParams,
  getMenuPathParams,
  getMenuKey,
  getMenuUrl,
  getGroupFirstLinkMenu,
} from '@components/sidebar/utils';
import useLayout from '@composables/useLayout';
import { useRouter } from '@/router/useRouter';
import { useStore } from '@store/index';
import { message } from 'ant-design-vue';

export default {
  name: 'NavMenuGroup',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: 56,
    },
  },
  setup(props) {
    const { disabled, height } = toRefs(props);
    const menuHeight = computed(() => ({
      lineHeight:
        typeof height.value === 'number'
          ? `${height.value}px`
          : `${height.value}`,
    }));

    const w = inject('w', null);

    const { router, route } = useRouter();
    const store = useStore();
    const {
      navTheme,
      showSidebarGroups,
      navShowSidebarGroups,
      navSidebarGroupSelectedKeys,
    } = useLayout();

    const stringInteropContext = computed(() => ({
      ...(w?.mainRenderer ?? {}),
      $app: Vue.prototype.$app,
      w,
    }));

    const isShowIcons = group => group?.showIcons ?? [true, false];
    const isShowMenus = groupId => showSidebarGroups?.value?.find(item => `${item.groupId}` === `${groupId}`)?.showMenus ?? false;
    const isMenuWithIcon = menu => !!menu?.args?.icon;
    const getMenuIcon = menu => menu?.args?.icon || '';
    const isMenuDisabled = (menu) => {
      try {
        const isPageMenu = menu?.args?.urlType === 'page';
        const originPath = getMenuUrl(menu);
        if (isPageMenu && isRegexPath(originPath)) {
          const { path } = normalizeRouteConfig({
            path: originPath,
            params: getMenuPathParams(menu),
            query: getMenuParams(menu),
            $route: route.value,
            stringInteropContext: stringInteropContext.value,
          });
          // 解析后依然为表达式的路径
          return isRegexPath(path);
        }
        return false;
      } catch (err) {
        return true;
      }
    };

    const selectedKeys = computed(() => {
      const [groupId] = navSidebarGroupSelectedKeys.value;
      const show = isShowMenus(groupId);
      if (show) {
        const sidebarInstance = store.state.layout.sidebar ?? null;
        return sidebarInstance?.selectedKeys?.map(key => `${groupId}:${key}`) ?? [];
      }
      return [...navSidebarGroupSelectedKeys.value];
    });

    // 菜单点击事件: 页面跳转
    const handleMenuClick = (menu) => {
      if (disabled.value) return message.warning('预览模式不支持操作');
      toPageByMenu(menu, {
        $router: router.value,
        $route: route.value,
        stringInteropContext: stringInteropContext.value,
      });
    };

    // 菜单组点击事件: 跳转到该菜单组下第一个链接
    const handleGroupClick = (group) => {
      if (disabled.value) return message.warning('预览模式不支持操作');
      const defaultMenu = getGroupFirstLinkMenu(group);
      if (!defaultMenu) return message.warning(`该导航组[${group.groupName}]没有配置页面菜单`);

      toPageByMenu(defaultMenu, {
        $router: router.value,
        $route: route.value,
        stringInteropContext: stringInteropContext.value,
      });
    };

    const getGroupMenuKey = (groupId, menus) => {
      if (menus?.length) return `${groupId}:${getMenuKey(menus)}`;
      return groupId;
    };

    return {
      navTheme,
      menuHeight,
      isShowIcons,
      isShowMenus,
      navShowSidebarGroups,
      selectedKeys,

      haveSubMenu,
      getGroupMenuKey,
      getSubMenu,
      getMenuIcon,
      isMenuWithIcon,
      isMenuDisabled,
      handleMenuClick,
      handleGroupClick,
    };
  },
};
</script>

<style lang="scss" scoped>
#xy-nav-menu .ant-menu-item,
#xy-nav-menu .ant-menu-submenu {
  margin-right: var(--xy-custom-nav-multi-menu-title-gap);
  font-size: var(--xy-custom-nav-font-size);
  min-width: 48px;
  text-align: center;
}
</style>


<style lang="scss">
#xy-nav-menu .ant-menu-item,
#xy-nav-menu .ant-menu-submenu .ant-menu-submenu-title {
  padding: 0;
}

/*
  导航菜单组（不含下拉框）
*/
#xy-runtime-layout .header,
#xy-preview-layout .header {
  #xy-nav-menu.ant-menu {
    .ant-menu-submenu {
      border-bottom: 0;
    }
    .ant-menu-item,
    .ant-menu-submenu > .ant-menu-submenu-title {
      color: var(--xy-custom-nav-color);
      line-height: 53px;
      border-bottom: 3px solid  transparent;
      background: transparent;
      border-radius: 0;
      &:hover {
        border-bottom: 3px solid transparent;
        color: var(--xy-custom-nav-hover-color);
      }
    }
    .ant-menu-submenu-selected,
    .ant-menu-submenu-open {
      > .ant-menu-submenu-title {
        color: var(--xy-custom-nav-active-color);
      }
    }

    .ant-menu-submenu-selected {
      > .ant-menu-submenu-title,
      > .ant-menu-submenu-title:hover {
        border-bottom: 3px solid  var(--xy-custom-nav-active-color);
      }
    }
    .ant-menu-item.ant-menu-item-selected{
      color: var(--xy-custom-nav-active-color);
      border-bottom: 3px solid  var(--xy-custom-nav-active-color);
    }
  }
}

/*
  导航菜单下拉框
*/
.xy-nav-menu-popup {
  min-width: var(--xy-custom-menu-pop-min-width);
  max-width: var(--xy-custom-menu-pop-max-width);

  .ellipsis {
    display: inline-block;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
  }
  .ant-menu-item {
    padding: var(--xy-custom-menu-pop-item-parent-padding) !important;
  }
  .ant-menu-submenu-title {
    padding: var(--xy-custom-menu-pop-item-padding) !important;
  }
  .title-container {
    display: flex;
    align-items: center;
  }

  // 导航菜单item背景圆角
  .ant-menu {
    .ant-menu-item,.ant-menu-submenu .ant-menu-submenu-title {
      border-radius: var(--xy-custom-menu-pop-item-border-radius);
      height: var(--xy-custom-menu-pop-item-height);
      margin: var(--xy-custom-menu-pop-item-margin) !important;
      .anticon {
        font-size: var(--xy-custom-menu-pop-item-icon-size);
        width: var(--xy-custom-menu-pop-item-icon-size);
        height: var(--xy-custom-menu-pop-item-icon-size);
        margin-right: var(--xy-custom-menu-pop-item-gap);
      }
    }
  }
}

.xy-nav-menu-popup.ant-menu-submenu-popup {
  .ant-menu.ant-menu-sub {
    background-color: var(--xy-custom-menu-pop-bg-color);
  }
  .ant-menu-item:not(.ant-menu-item-selected),
  .ant-menu-submenu:not(.ant-menu-submenu-selected) .ant-menu-submenu-title {
    color: var(--xy-custom-menu-pop-item-text-color);
    &:hover {
      color: var(--xy-custom-menu-pop-item-hover-text-color); // 菜单item hover字体颜色
      background-color: var(--xy-custom-menu-pop-item-hover-bg-color);
    }
    &:active {
      color: var(--xy-custom-menu-pop-item-active-text-color); // 菜单item 点击背景色
      background-color: var(--xy-custom-menu-pop-item-active-bg-color);
    }
  }

  .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: 1;
    &::before,&::after {
      background: var(--xy-custom-menu-pop-arrow-color);
    }
  }

  .ant-menu-submenu.ant-menu-submenu-selected > .ant-menu-submenu-title, // 子菜单 选中
  .ant-menu-item.ant-menu-item-selected { // 没有子菜单 被选中
    color: var(--xy-custom-menu-pop-item-selected-text-color);
    background-color: var(--xy-custom-menu-pop-item-selected-bg-color);
    .anticon,.anticon + span {
      color: var(--xy-custom-menu-pop-item-selected-text-color);
    }
    .ant-menu-submenu-arrow {
      &::before,&::after {
        background: var(--xy-custom-menu-pop-item-selected-text-color);
      }
    }
    &:hover,&:active {
      color: var(--xy-custom-menu-pop-item-selected-text-color);
      background-color: var(--xy-custom-menu-pop-item-selected-bg-color);
    }
  }
}

</style>
