import { uuidv4 } from '@tencent/ui-core/lib/utils';
import { ref } from '@vue/composition-api';
import dependencyLoader from '@utils/dependencyLoader';
export const SESSION_STORAGE_KEY = 'previewConfig';
export const PREVIEW_MODE_QUERY_KEY = 'previewMode';
const PROVIDER_SOURCE = 'EDITOR_PREVIEW_DATA_CHANGE';
const CONSUMER_SOURCE = 'PREVIEWER_INIT';
var ProviderMessageType;
(function (ProviderMessageType) {
    ProviderMessageType["updatePageConfig"] = "updatePageConfig";
    ProviderMessageType["updateDependency"] = "updateDependency";
})(ProviderMessageType || (ProviderMessageType = {}));
;
const BROADCAST_CHANNEL_ID = 'preview-mode-broadcast-channel';
/**
 * 编辑器使用的 previewProvider
 *
 * provider的意思是提供页面信息的一方
 * @returns
 */
export const usePreviewProvider = () => {
    const { createHook, destroyHook, postMessage } = usePreviewBaseProvider();
    const editorCreatedHook = (editor) => {
        const getPayload = () => ({
            baseData: editor.baseData,
            projectInfo: editor.projectInfo,
        });
        createHook(getPayload);
    };
    const editorDestroyedHook = () => {
        destroyHook();
    };
    const switchActiveKeyHook = (editor, key) => {
        if (key === 'renderer') {
            const getPayload = () => ({
                baseData: editor.baseData,
                projectInfo: editor.projectInfo,
            });
            postMessage(getPayload(), ProviderMessageType.updatePageConfig);
        }
    };
    return {
        editorCreatedHook,
        editorDestroyedHook,
        switchActiveKeyHook,
    };
};
/**
 * 用于预览模式的基础数据提供者
 *
 * 用于拓展不同场景下需要使用预览模式的组件
 *
 * @param previewSessionKey 用于区别不同的预览会话
 * @returns
 */
export const usePreviewBaseProvider = (previewSessionKey = '') => {
    const broadcastChannel = ref(null);
    let hookId = null;
    const createHook = (getPayload) => {
        const sessionId = uuidv4();
        sessionStorage.setItem(SESSION_STORAGE_KEY + previewSessionKey, sessionId);
        broadcastChannel.value = new BroadcastChannel(`${BROADCAST_CHANNEL_ID}_${sessionId}`);
        broadcastChannel.value.onmessage = (event) => {
            const { source } = event.data;
            if (source === CONSUMER_SOURCE) {
                postMessage(getPayload(), ProviderMessageType.updatePageConfig);
            }
        };
        hookId = dependencyLoader.hooks.register.tap('updateDep', (module) => {
            postMessage(module, ProviderMessageType.updateDependency);
        });
    };
    const destroyHook = () => {
        broadcastChannel.value?.close();
        broadcastChannel.value = null;
        dependencyLoader.hooks.register.untap(hookId);
    };
    const postMessage = (payload, messageType) => {
        broadcastChannel.value?.postMessage({
            messageType,
            source: PROVIDER_SOURCE,
            payload,
        });
    };
    return {
        createHook,
        destroyHook,
        postMessage,
    };
};
export const isPreviewMode = () => {
    const querySearch = new URLSearchParams(window.location.search);
    return querySearch.get(PREVIEW_MODE_QUERY_KEY) === '1';
};
export const usePreviewConsumer = (previewSessionKey = '') => {
    const broadcastChannel = ref(null);
    const fetchPreviewData = () => {
        // 发送消息，通知编辑器已经初始化完成
        // 让编辑器推送第一次数据过来
        broadcastChannel.value?.postMessage({
            source: CONSUMER_SOURCE,
        });
    };
    const addPreviewListener = (pagelet) => {
        const sessionId = sessionStorage.getItem(SESSION_STORAGE_KEY + previewSessionKey);
        window.RUNTIME_CONFIGS.isPreviewMode = true;
        if (!sessionId) {
            console.error('[预览模式]获取sessionId失败');
            return;
        }
        broadcastChannel.value = new BroadcastChannel(`${BROADCAST_CHANNEL_ID}_${sessionId}`);
        broadcastChannel.value.onmessage = (event) => {
            const { source, payload, messageType } = event.data;
            if (!(source === PROVIDER_SOURCE)) {
                return;
            }
            if (messageType === ProviderMessageType.updatePageConfig) {
                pagelet.setConfig(payload.baseData);
                if (payload.projectInfo) {
                    pagelet.setProjectConfig(payload.projectInfo);
                }
                pagelet.reloadUcRenderer();
            }
            if (messageType === ProviderMessageType.updateDependency) {
                pagelet.updateDependency(payload);
            }
        };
        fetchPreviewData();
    };
    const removePreviewListener = () => {
        broadcastChannel.value?.close();
        broadcastChannel.value = null;
    };
    return {
        isPreviewMode,
        addPreviewListener,
        fetchPreviewData,
        removePreviewListener,
    };
};
